import React from 'react';
import Link from 'components/Link';
import { formatCreationWithIntl } from 'components/Premium/utils';
import CardNotification from './CardNotification';
import { ReviewCoachNotificationTemplateProps } from '../types';

export default function ReviewCoachNotificationTemplate({
  status,
  badgeComponent,
  title,
  href,
  date,
  coachName,
}: ReviewCoachNotificationTemplateProps) {
  const [initialStatus] = React.useState(status);

  const finalMessage = 'Leave a review for your coach';

  return (
    <Link href={href} className="block">
      <CardNotification status={initialStatus}>
        <div className="p-2">
          <div className="flex items-center justify-between">
            <span className="text-xs font-bold">{coachName}</span>
            {badgeComponent}
          </div>

          {/* Display the final message with or without the price sentence */}
          <div className="mt-1 text-xs leading-5">{finalMessage}</div>

          <span className="text-sm font-bold">{title}</span>

          <div className="flex gap-2 text-xs">
            <span className="text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
              Submitted on
            </span>
            {date && (
              <span className="text-color-brand-primary">
                {formatCreationWithIntl(date, false)}
              </span>
            )}
          </div>
        </div>
      </CardNotification>
    </Link>
  );
}
